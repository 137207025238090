'use client'

import { type NewsletterBoxProps } from '.';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Button from '@components/atoms/button/Button';
import FormCheck from '@components/atoms/form-check/FormCheck';
import Form from '@components/atoms/form';
import FormControl from '@components/atoms/form-control';
import { ModalCMS } from '@components/molecules/modal';
import t from '@/utils/translation-faker';

const NewsletterBox = ({ onSubmit, validationSchema, loading = false, sent = false, ...props }: NewsletterBoxProps) => {

  return (
    <Container className={'px-0  px-sm-2  py-5  text-gray-200'} {...props} >
      <Row>
        <div className={'mb-4  mb-md-0 col-lg-6 col-12'}>
          <span className={'H3-Headline-White  fw-bold'}>{t('TransPak Newsletter')}</span>
          <p className={'pe-5  fs-6'}>{t('newsletter_info')}</p>
        </div>
        <div className={'col-lg-6  col-12'}>
          {false === sent ?
            <Form onSubmit={onSubmit} showErrors={false} initialValues={{ firstName: '', lastName: '', email: '', company: '', zipCode: '', datenschutz: false }} validationSchema={validationSchema}>
              <div className={'d-flex  justify-content-between  flex-column  flex-md-row  gap-md-3  mb-2'}>
                <FormControl id={'firstName'} className={'fs-8 mt-2  rounded-0'} placeholder={`${t('Vorname')}`} required></FormControl>
                <FormControl id={'lastName'} className={'fs-8 mt-2  rounded-0'} placeholder={`${t('Nachname')}`} required></FormControl>
              </div>
              <div className={'d-flex  flex-column  flex-sm-row  justify-content-between  gap-sm-3  mb-2'}>
                <FormControl id={'zipCode'} className={'w-100 w-sm-auto fs-8 mb-2 mb-sm-0  rounded-0  newsletter-input__zipcode'} placeholder={`${t('Postleitzahl')}`} required></FormControl>
                <FormControl id={'company'} className={'w-100 fs-8  rounded-0'} placeholder={`${t('Firma')}`} required></FormControl>
              </div>
              <div className={'mb-4'}>
                <FormControl id={'email'} className={'fs-8  rounded-0'} placeholder={`${t('Ihre E-Mail Adresse')}`} required></FormControl>
              </div>
              <div className={'d-flex  justify-content-between  flex-column  flex-md-row  align-items-md-center  text-white'}>
                <FormCheck
                  type={'checkbox'}
                  id={'datenschutz'}
                  label={(<>
                    {'Ich habe die '}<ModalCMS title={'Datenschutzbestimmungen'} buttonText={'Datenschutzbestimmungen '} cmsPageId={process.env.NEXT_PUBLIC_PRIVACY_POLICY ?? ''} />{' zur Kenntnis genommen.'}
                  </>)}
                  className={'d-inline-flex  align-items-center  gap-2  fs-8  flex-grow  mb-3  pe-4  mb-xl-0'}
                  required
                />
                <Button type={'submit'} disabled={loading} className={'w-100  w-sm-auto  fw-bold  text-white  ps-2'} shadow centered >
                  <span className={'d-none  d-sm-block  icon  icon--chevron-left  icon--white  icon--size__sm  icon--valign__middle'}></span>
                  <span>{t('Jetzt anmelden')}</span>
                </Button>
              </div>
            </Form>
            : (
              <div className={'px-4'}>
                <p>Vielen Dank für Ihre Anmeldung zu unserem Newsletter.</p>
                <p>Wir haben Ihnen einen Bestätigungslink per E-Mail zugesendet. Nachdem Sie diesen Link bestätigt haben, gelangen Sie direkt zu Ihrem 5 % Rabatt-Gutschein für Ihre nächste Bestellung im TransPak Onlineshop.</p>
                <p>Wir freuen uns auf Ihre Bestellung.</p>
              </div>
            )}
        </div>
      </Row>
    </Container>
  );
};

export default NewsletterBox;
