'use client'

import type { FooterNavigationItemProps } from "."

import { useSession } from "next-auth/react"

const FooterNavigationItem = ({ label, children }: FooterNavigationItemProps) => {
    const { data: session } = useSession()

    if (true === session?.user?.group?.displayGross && 'newsletter' === label.toLowerCase()) {
        return null
    }

    return children
}

export default FooterNavigationItem
